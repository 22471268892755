import formatWord from '../utility/formatWord'
import lineHeight from '../utility/lineHeight'
import stop from './stop'
import wordsPerLine from './wordsPerLine'
import isElement from '../utility/isElement'

// TODO: Figure out why force is necessary? Please use sparingly.
const setWordPositions = (force = false) => {
  if (window.state.positioning && !force) {
    Logger('already positioning')
    return
  }

  window.state.lastWordNum = '0'

  window.state.positioning = true
  delete window.state.wordMap

  const script = document.getElementById('script')

  if (!state.scrollMethod == 'auto') {
    stop(window.state)
  }

  if (!script) return;

  setTimeout(() => {
    window.state.wordMap = []
    let positions = []
    const spans = Array.from(script.querySelectorAll('span[data-word]'))
    const paragraphs = Array.from(script.querySelectorAll('p'))
    const elements = spans.concat(paragraphs)

    let count = 0;
    elements.forEach((element, i) => {
      let formattedWord = formatWord(element.innerText)
      element.dataset.position = Math.round(element.getBoundingClientRect().top + window.pageYOffset)

      const isBracketed = element.dataset.bracketed == ''
      // bracketted only matters on flow

      if (isElement(element, 'span') && (formattedWord.split(' ').length == 1 && (window.state.scrollMethod != 'voice' || !isBracketed))) {
        window.state.wordMap.push({
          num: count,
          word: formattedWord,
          element
        })
        element.dataset.word = formattedWord
        element.dataset.num = count
        count++
      }

      window.state.totalNumWords = i
    })

    const perLine = wordsPerLine()

    let foo = (lineHeight()/perLine)

    Array.from(document.querySelectorAll('[data-position]')).forEach((word) => {
      if (positions.indexOf(parseFloat(word.dataset.position)) == -1) {
          positions.push(parseFloat(word.dataset.position))
          foo = (lineHeight()/perLine)
      }
    })

    window.state.positions = Array.from(new Set(positions)).sort((a,b) => a-b) // gotta sort ...bc JS. 🙃

    window.state.lineHeight = lineHeight()

    window.dispatchEvent(new CustomEvent('script_ready', {}))
    Uatu.emit('set_positions')
    window.state.positioning = false
  }, 1000) // you gotta wait a second to do it (bc animations)
}

export default setWordPositions
