import fetchToken from "./fetchToken"
const TEN_MINUTES = 600000

const renewToken = ({
  recognizer,
  tokenAttribute
}) => {
  setInterval(() => {
    if (recognizer && window.store.prompter.active) {
      fetchToken(state, () => {
        recognizer[tokenAttribute] = window.state.token
      })
      Logger('RenewToken: set new token', window.state.token)
    }
  }, TEN_MINUTES)
}

export default renewToken
