import goTo from './goTo'
import clearAfter from './clearAfter'
import registerWordAsSpoken from './registerWordAsSpoken'

const wordClickHandler = (state) => {
  const handler = (e) => {
    setState('interrupted', false)
    clearAfter(e.target)
    registerWordAsSpoken(e.target, { to: 'bookmark' })
    goTo(e.target.dataset.position, 500)

    if (state.timer) state.timer.resetInterval()
  }

  Array.from(document.querySelectorAll('span[data-word]')).forEach((word) => {
    word.addEventListener('click', handler)
  })
}

export default wordClickHandler
