import Speech from "./speech";

const DELAY = 3000;
const TOKEN_EXPIRATION = 2 * 60 * 1000; // 2 minutes in milliseconds
const TOKEN_STORAGE_KEY = 'tokenData';

const fetchToken = (state, cb) => {
  const currentTime = new Date().getTime();
  const storedTokenData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY) || '{}');

  if (storedTokenData.token && (currentTime - storedTokenData.timestamp < TOKEN_EXPIRATION)) {
    state.token = storedTokenData.token;
    Logger('reusing token', state.token)
    cb();
    return;
  }

  let service = window.state.service || 'other';
  window.state.service = service;

  fetch(`${window.location.origin}/theme`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      authenticity_token: window._token,
      option: 'fetch',
      service: service,
    })
  })
  .then((data) => data.json())
  .then(function(_token) {
    state.token = _token.access_token;
    const tokenData = {
      token: _token.access_token,
      timestamp: currentTime
    };
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(tokenData));
    Logger('fetched token', state.token);
    setTimeout(cb, DELAY);
  }).catch(function(error) {
    window.swal('Something went wrong...', `
      An error occurred... please <a href="javascript:window.location.href=window.location.href">refresh this page</a>
      or contact support <a href="mailto:team@speakflow.com">team@speakflow.com</a>.
      This error will be resolved shortly.
    `, 'warning', 'Okay', null, null, null);
    Logger('something went wrong', error);
  });
}

export default fetchToken;
