import AssemblyAI from '../classes/assemblyai';
import Display from '../utility/Display';
import formatWord from '../utility/formatWord';
import hasFeature from '../utility/hasFeature';
import renewToken from './renewToken';

class Speech {
  constructor(state) {
    this.isMobile = state?.isMobile
    this.isChrome = state?.isChrome
    this.service = null
    this.results = ''
    this.resultLength = 0
    this.token = state?.token
    this.language = state?.language || 'en-US'
  }

  async setService(service, shouldStart = true) {
    this.service = 'other'

    if (window.state.language && window.state.language.includes('en')) {
      this.service = 'assembly' // as of now, assembly only supports english
    }

    window.state.service = this.service

    if (!shouldStart) return; // you may want to set state.service without actually kicking off listening

    if (this.service == 'chrome') {
      this.service = 'chrome'
      let recognition = new webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.maxAlternatives = 0

      recognition.onend = function() {
        if (window.state.lastWordNum == 0) return;

        Logger('recognition ended')
        try {
          if (window.state.stream) {
            recognition.start();
            Logger('recognition restarted')
          }
        } catch (error) {
          // console.log(error)
        }
      }

      recognition.start()

      this.stream = recognition
      window.state.stream = recognition
    } else if (this.service == 'other') {
      this.service = 'other'
      try {
        let audioConfig;
        let audioSource = window.state?.videoSettings?.mic
        let speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(window.state.token, 'eastus');
        speechConfig.speechRecognitionLanguage = window.state.language
        speechConfig.setServiceProperty('')
        Logger('starting...', audioSource)
        if (audioSource) {
          Logger('starting with audioSource', audioSource)
          audioConfig = SpeechSDK.AudioConfig.fromMicrophoneInput(audioSource)
        } else {
          Logger('starting with default audioSource')
          audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        } //
        let recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

        recognizer.startContinuousRecognitionAsync();

        this.stream = recognizer
        window.state.stream = recognizer

        renewToken({
          recognizer,
          tokenAttribute: 'authorizationToken'
        })

      } catch (err) {
        console.error('Erorr', err);
        if (!!window.SpeechSDK) {
          console.error('Erorr', err);
        }
      }
    } else if (this.service == 'assembly') {
      AssemblyAI.init(window.state.token, { speech: this })
    }

    this.setIncomingDataHandlers();
  }

  async setStream() {
    const { videoSettings } = window.state
    const customStream = Object.keys(videoSettings).length

    if (!customStream && !this.isMobile && this.isChrome && this.language == 'en-US') {
      this.setService('chrome')
    } else {
      this.setService('other')
    }

    Logger(`service: ${this.service}`)
    window.state['service'] = this.service
  }

  setIncomingDataHandlers() {
    if (this.service == 'chrome') {
      this.stream.onresult = (event) => {
        this.results = [formatWord(event.results[event.results.length - 1][0].transcript.split(' ').pop())]
        this.resultLength = event.results[event.results.length - 1][0].transcript.split(' ').length
        if (event.results[0][0].confidence > 0.4) {
          this.dispatch(this.results)
        }
      }
      this.stream.onerror = (event) => {
        console.error('Speech recognition error:', event);

        if (event.error == 'not-allowed') {
          Display.permissionsError('setIncomingDataHandlers')
        } else {
          // try again
          this.setService('chrome')
        }
      }
    } else if (this.service == 'other') {
      this.stream.recognizing = (r, event) => {
        let results = event.privResult?.privText?.split(' ').splice(-1)
        results.forEach((result) => {
          this.dispatch([formatWord(result)])
        })
      }
    }
  }

  dispatch(results) {
    window.dispatchEvent(new CustomEvent('new_stream_results', {
      detail: { results }
    }))
  }
}

export default Speech
