import formatWord from "../utility/formatWord";

let socket;
let recorder;
let mediaStream;

const AssemblyAI = {
  init: async (token, { speech }) => {
    socket = await new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

    // handle incoming messages to display transcription to the DOM

    socket.onmessage = (message) => {
      const res = JSON.parse(message.data);
      let word = formatWord(res.words?.pop()?.text);
      if (word) speech.dispatch([word])
    };

    socket.onerror = (event) => {
      console.error(event);
      socket.close();
    }

    socket.onclose = event => {
      console.log(event);
      socket = null;
    }

    socket.onopen = () => {
      // once socket is open, begin recording
      let mic = window.state?.videoSettings?.mic

      let constraints = {
        audio: mic ? { deviceId: mic } : true
      }

      window.StreamManager.create(constraints)
      .then((streamInfo) => {
        recorder = new RecordRTC(streamInfo.stream, {
          type: 'audio',
          mimeType: 'audio/webm;codecs=pcm', // endpoint requires 16bit PCM audio
          recorderType: StereoAudioRecorder,
          timeSlice: 250, // set 250 ms intervals of data that sends to AAI
          desiredSampRate: 16000,
          numberOfAudioChannels: 1, // real-time requires only one channel
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: (blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64data = reader.result;

              // audio data must be sent as a base64 encoded string
              if (socket) {
                try {
                  sendData(base64data)
                } catch (error) {
                  console.error('Error sending audio data:', error);
                  // restart();
                }
              }
            };
            reader.readAsDataURL(blob);
          },
        });
        mediaStream = streamInfo.stream;
        recorder.startRecording();
        speech.stream = recorder;

        // Store the streamId for later use (e.g., stopping the stream)
        speech.streamId = streamInfo.id;
      })
      .catch((err) => console.error(err));
    };

    Uatu.on('script_stop', () => {
      if (!socket || !recorder) return;

      socket.close();
      socket = null;
      recorder.stopRecording();

      window.StreamManager.stopAll();
    })
  }
}

export default AssemblyAI;

function sendData(base64data) {
  const dataToSend = JSON.stringify({ audio_data: base64data.split('base64,')[1] });

  // Check if the WebSocket is open before sending data
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(dataToSend);
  } else if (socket.readyState === WebSocket.CONNECTING) {
    // Wait until the connection is open
    socket.addEventListener('open', () => {
      socket.send(dataToSend);
    });
  } else {
    console.error('WebSocket is not open. Current state:', socket.readyState);
  }
}
